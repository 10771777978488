<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.Name }}</td>
                            <td>{{ item.ShortName }}</td>
                            <td>{{ item.IsAutoCustomer ? 'Y' : ''}}</td>
                            <td class="text-center">
                                <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">fa-edit</v-icon>
                                <v-icon medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        return {
            headers: [
                {
                    text: "Tên kho",
                    value: "Name",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Tên viết tắt",
                    value: "ShortName",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Tự động chia khách",
                    value: "IsAutoCustomer",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "warehouse_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: mapState({
        items: state => state.warehouse.all.data,
        total_rows: state => state.warehouse.all.total,
        loading: state => state.warehouse.loading,
        currentItem: state => state.warehouse.selected
    }),
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("warehouse/getList", param);
        },
        gotoDetail: function (id) {
            this.$router.push({
                name: "WarehouseDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết kho hàng (#${id})`
                }
            });
        },
        deleteItem: function(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$router.push({
                name: "WarehouseDetail",
                params: {
                Pid: 'tao-moi',
                title: `Tạo mới kho hàng`
                }
            });
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("warehouse/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
